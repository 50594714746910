import "./App.css";
import "./Assets/styles/custom.scss";
import "./Assets/styles/responsive.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";
import Culinary from "./Components/Culinary";
import Stay from "./Components/Stay";
import Explore from "./Components/Explore";
import Enjoy from "./Components/Enjoy";
import DoubleDeluxe from "./Components/rooms/DoubleDeluxe";
import FamilyDeluxe from "./Components/rooms/FamilyDeluxe";
import FamilyDeluxeAC from "./Components/rooms/FamilyDeluxeAC";
import PrinceSuite from "./Components/rooms/PrinceSuite";
import Gallery from "./Components/Gallery";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dining" element={<Culinary />} />
          <Route path="/rooms" element={<Stay />} />
          <Route path="/celebrate" element={<Enjoy />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/gallery" element={<Gallery />} />

          <Route path="/rooms/doubledeluxe" element={<DoubleDeluxe />} />
          <Route path="/rooms/familydeluxe" element={<FamilyDeluxe />} />
          <Route path="/rooms/familydeluxeac" element={<FamilyDeluxeAC />} />
          <Route path="/rooms/princesuite" element={<PrinceSuite />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
