import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/img/logo.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { FaEnvelope } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
export default function Footer() {
  const socials = [
    {
      icon: <FaFacebook />,
    },
    {
      icon: <FaInstagram />,
    },
    {
      icon: <FaXTwitter />,
    },
    {
      icon: <FaLinkedinIn />,
    },
  ];

  const rooms = [
    {
      url: "/rooms/doubledeluxe",
      title: "Double Deluxe Non A/C",
    },
    {
      url: "/rooms/familydeluxe",
      title: "Family Deluxe Non A/C",
    },
    {
      url: "/rooms/familydeluxeac",
      title: "Family Deluxe A/C",
    },
    {
      url: "/rooms/princesuite",
      title: "Prince Suite A/C",
    },
  ];

  const quicklinks = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/dining",
      title: "Dining",
    },
    {
      url: "/celebrate",
      title: "Celebrate",
    },
    {
      url: "/explore",
      title: "Explore Chitradurga",
    },
    {
      url: "/gallery",
      title: "Gallery",
    },
    // {
    //   url: "https://aishwaryafort.com/blog/",
    //   title: "Blog",
    // },
    {
      url: "/contact",
      title: "Contact",
    },
    {
      url: "tel:919620537978",
      title: "Book A Stay",
    },
  ];
  return (
    <>
      <div className="footer">
        <div className="overlay ptb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-3 mobcenter mobpb">
                <div>
                  <div>
                    <Link to="/">
                      {" "}
                      <img src={Logo} alt="" className="img-fluid" />
                    </Link>
                  </div>
                  <div className="socialicons">
                    <div className="pt-2">
                      {socials.map((item, index) => (
                        <>
                          <span key={index}>
                            <Link to="">
                              <i>{item.icon}</i>
                            </Link>
                          </span>
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="pt-4">
                    <small>
                      © 2024 <Link to="/">Hotel Aishwarya Fort.</Link> <br />
                      All Rights Reserved.
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mobcenter mobpb">
                <h6>Rooms</h6>
                <ul className="list-unstyled">
                  {rooms.map((item) => {
                    return (
                      <li key={item.url}>
                        <Link to={item.url}>{item.title}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-md-3 mobcenter mobpb">
                <h6>Quick Links</h6>
                <ul className="list-unstyled">
                  {quicklinks.map((item) => {
                    return (
                      <li key={item.url}>
                        <Link to={item.url}>{item.title}</Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-md-3 mobcenter">
                <h6>Reach Us</h6>
                <ul className="list-unstyled reach">
                  <li>
                    <Link to="https://maps.app.goo.gl/SRLqWNwHA3XUFjpG9">
                      <FaMapMarkerAlt />{" "}
                      <span>
                        Hotel Aishwarya Fort, Turuvanur Rd, near RTO Office,
                        Maniyur, Chitradurga, Karnataka 577501
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link to="tel:919620537978">
                      <MdCall /> <span>+91 9620537978</span>
                    </Link>{" "}
                    {" | "}
                    <Link to="tel:08194231823">
                      <span>08194-231823/824</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="mailto:aishwaryafort2000@gmail.com">
                      <FaEnvelope /> <span>aishwaryafort2000@gmail.com</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
