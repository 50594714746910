import React, { useState, useEffect } from "react";
import Wedding from "../Assets/img/wed.png";
import Corp from "../Assets/img/corp.png";
import Inti from "../Assets/img/inti.png";
import Button from "./Button";

export default function Enjoy() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [Wedding, Corp, Inti]; // You can replace these with different images

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <>
      <div className="enjoy section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <img src={images[currentImage]} alt="" className="img-fluid" />
            </div>
            <div className="col-md-8 mobtop">
              <h4>Celebrate With Us</h4>
              <p>
                Whether it's a grand wedding, a corporate conference, or an
                intimate gathering, our versatile spaces are perfect for any
                occasion.
              </p>
              <h5>Let us transform your vision into reality</h5>
              <Button
                url="tel:919620537978"
                title="Book An Event"
                className="pt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
