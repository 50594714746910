import React from "react";
import LightboxGallery from "./LightboxGallery";

export default function Gallery() {
  return (
    <>
      <div className="enjoy section">
        <div className="container">
          <LightboxGallery />
        </div>
      </div>
    </>
  );
}
