import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import g1 from "../Assets/img/gallery/1.png";
import g2 from "../Assets/img/gallery/2.png";
import g3 from "../Assets/img/gallery/3.png";
import g4 from "../Assets/img/gallery/4.png";
import g5 from "../Assets/img/gallery/5.png";
import g6 from "../Assets/img/gallery/6.png";
import g7 from "../Assets/img/gallery/7.png";
import g8 from "../Assets/img/gallery/8.png";
import g9 from "../Assets/img/gallery/9.png";
import g10 from "../Assets/img/gallery/10.png";
import g11 from "../Assets/img/gallery/11.png";
import g12 from "../Assets/img/gallery/12.png";
import g13 from "../Assets/img/gallery/13.png";
import g14 from "../Assets/img/gallery/14.png";
import g15 from "../Assets/img/gallery/15.png";
import g16 from "../Assets/img/gallery/16.png";
import g17 from "../Assets/img/gallery/17.png";
import g18 from "../Assets/img/gallery/18.png";
import g19 from "../Assets/img/gallery/19.png";
import g20 from "../Assets/img/gallery/20.png";
import g21 from "../Assets/img/gallery/21.png";
import g22 from "../Assets/img/gallery/22.png";
import g23 from "../Assets/img/gallery/23.png";
import g24 from "../Assets/img/gallery/24.png";
import g25 from "../Assets/img/gallery/25.png";
import g26 from "../Assets/img/gallery/26.png";
import g27 from "../Assets/img/gallery/27.png";
import g28 from "../Assets/img/gallery/28.png";
import g29 from "../Assets/img/gallery/29.png";
import g30 from "../Assets/img/gallery/30.png";
import g31 from "../Assets/img/gallery/31.png";
import g32 from "../Assets/img/gallery/32.png";
import g33 from "../Assets/img/gallery/33.png";
import g34 from "../Assets/img/gallery/34.png";
import g35 from "../Assets/img/gallery/35.png";
import g36 from "../Assets/img/gallery/36.png";
import g37 from "../Assets/img/gallery/37.png";
import g38 from "../Assets/img/gallery/38.png";
import g39 from "../Assets/img/gallery/39.png";
import g40 from "../Assets/img/gallery/40.png";
import g41 from "../Assets/img/gallery/41.png";
import g42 from "../Assets/img/gallery/42.png";
import g43 from "../Assets/img/gallery/43.png";

const LightboxGallery = () => {
  const images = [
    g1,
    g2,
    g3,
    g4,
    g5,
    g6,
    g7,
    g8,
    g9,
    g10,
    g11,
    g12,
    g13,
    g14,
    g15,
    g16,
    g17,
    g18,
    g19,
    g20,
    g21,
    g22,
    g23,
    g24,
    g25,
    g26,
    g27,
    g28,
    g29,
    g30,
    g31,
    g32,
    g33,
    g34,
    g35,
    g36,
    g37,
    g38,
    g39,
    g40,
    g41,
    g42,
    g43,
  ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="row">
        {images.map((item, index) => (
          <div className="col-md-4 col-6">
            <img
              key={index}
              src={item}
              onClick={() => {
                setPhotoIndex(index);
                setIsOpen(true);
              }}
              alt={`Gallery img`}
              style={{ cursor: "pointer", marginBottom: "20px" }}
              className="img-fluid"
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default LightboxGallery;
