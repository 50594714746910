import React, { useState } from "react";

import { IoMenu } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import Logo from "../Assets/img/logo.svg";

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const Navbar = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Dining",
      link: "/dining",
    },
    {
      text: "Rooms",
      link: "/rooms",
    },
    {
      text: "Celebrate",
      link: "/celebrate",
    },
    {
      text: "Explore Chitradurga",
      link: "/explore",
    },
    {
      text: "Gallery",
      link: "/gallery",
    },
    // {
    //   text: "Blog",
    //   link: "https://aishwaryafort.com/blog/",
    // },
    {
      text: "Contact",
      link: "/contact",
    },
    {
      text: "Book A Stay",
      link: "tel:919620537978",
    },
  ];

  return (
    <div className="header">
      <nav className="container">
        <div className="nav-logo-container">
          <NavLink to="/">
            <img src={Logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <div className="navbar-links-container">
            <NavLink to="/" activeclassname="active">
              Home
            </NavLink>
            <NavLink to="/dining" activeclassname="active">
              Dining
            </NavLink>
            <NavLink to="/rooms" activeclassname="active">
              Rooms
            </NavLink>
            <NavLink to="/celebrate" activeclassname="active">
              Celebrate
            </NavLink>
            <NavLink to="/explore" activeclassname="active">
              Explore Chitradurga
            </NavLink>
            <NavLink to="/gallery" activeclassname="active">
              Gallery
            </NavLink>
            {/* <NavLink
              to="https://aishwaryafort.com/blog/"
              activeclassname="active"
            >
              Blog
            </NavLink> */}
            <NavLink to="/contact" activeclassname="active">
              Contact
            </NavLink>
            <NavLink to="tel:919620537978" target="_blank">
              <b>Book a stay</b>
            </NavLink>
          </div>
          <button className="themebtn" onClick={props.handleMode}>
            {props.btnIcon}
          </button>
        </div>
        <div className="navbar-menu-container">
          <IoMenu onClick={() => setOpenMenu(true)} className="menu-bar-icon" />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
            className={`mob-nav-area `}
          >
            <List className="mob-nav">
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton>
                    <ListItemText className="men-items">
                      <NavLink to={item.link}>{item.text}</NavLink>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
