import React from "react";
import { Link } from "react-router-dom";

export default function SimilarRooms() {
  return (
    <>
      <div className="pt-5">
        <h5>All Rooms:</h5>
        <Link to="/rooms/doubledeluxe">Double Deluxe Non A/C</Link> {" | "}
        <Link to="/rooms/familydeluxe">Family Deluxe Non A/C</Link> {" | "}
        <Link to="/rooms/familydeluxeac">Family Deluxe A/C</Link> {" | "}
        <Link to="/rooms/princesuite">Prince Suite A/C</Link>
      </div>
    </>
  );
}
